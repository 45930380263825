<template>
  <div class="page-auth">
    <div class="page-main">
      <div class="main-text">请上传您的身份证正反面照片</div>
      <div class="i-body">
        <div class="i-photo">
          <van-uploader :preview-image="false" :preview-full-image="false" class="a-vant-upload"
                        :after-read="(file)=>afterRead(file,'Front')"/>
          <img class="i-photo-img" :src="frontImgPath ?  frontImgPath : identityFront"/>
        </div>

        <div class="i-photo">
          <van-uploader :preview-image="false" :preview-full-image="false" class="a-vant-upload"
                        :after-read="(file)=>afterRead(file,'Back')"/>
          <img class="i-photo-img" :src="reverseImgPath ? reverseImgPath : identityBack"/>
        </div>
      </div>
      <div class="i-req">
        <div class="req-header">
          <i class="req-frist"></i>
          <span>拍摄照片要求</span>
          <i class="req-secord"></i>
        </div>
        <div class="req-mian">
          <div v-for="item in intro" :key="item.type" class="req-main-img">
            <img :src="item.img"/>
          </div>
        </div>
      </div>
    </div>
    <div class="auth-btn" @click="authVerify">认证</div>

    <!--确认弹框-->
    <van-popup
      closeable
      position="bottom"
      :style="{ height: '440px' }"
      v-model:show="visible">
      <div class="ocr-confirm">
        <h4>信息确认</h4>
        <div class="c-form">

          <van-field v-model="certInfo.realName" label-class="c-label" label="姓名" input-align="right"/>
          <van-field v-model="certInfo.idCardNumber" label-class="c-label" label="身份证号码" input-align="right"/>
          <van-field v-model="certInfo.validDate" label-class="c-label" label="身份证有效期" input-align="right"/>


          <div class="bank-picture">
            <div class="pic-img">
              <img :src="frontImgPath" alt="身份证正面"/>
            </div>
            <div class="pic-img pic-c">
              <img :src="reverseImgPath" alt="身份证反面"/>
            </div>
          </div>
          <div class="bank-btn" @click="handleSubmit">
            <span>确认</span>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import {onMounted, reactive, toRefs} from "vue";
import {Jump} from '@/utils/routh'
import API from "../../server/api";
import {UploadFile} from "@/utils/upload";
import {Toast} from 'vant'
import ls from 'local-storage'

export default {
  setup() {
    const state = reactive({
      visible: false,
      identityFront: require("./img/cardface.png"), // 身份证正deletable: false,
      identityBack: require("./img/cardreserve.png"), // 身份证反
      frontImgPath: null,
      reverseImgPath: null,
      intro: [
        {
          type: "https://res.feiyiyc.com/public/static/imgO.png",
          img: "https://res.feiyiyc.com/public/static/imgO.png",
          txt: "标准拍摄",
        },
        {
          type: "https://res.feiyiyc.com/public/static/imgS.png",
          img: "https://res.feiyiyc.com/public/static/imgS.png",
          txt: "边框缺失",
        },
        {
          type: "https://res.feiyiyc.com/public/static/imgT.png",
          img: "https://res.feiyiyc.com/public/static/imgT.png",
          txt: "照片模糊",
        },
        {
          type: "https://res.feiyiyc.com/public/static/imgF.png",
          img: "https://res.feiyiyc.com/public/static/imgF.png",
          txt: "闪光强烈",
        },
      ],

      certInfo: {
        "address": "",
        "frontImgPath": "",
        "idCard": "",
        "issuedBy": "",
        "overdueDate": "",
        "race": "",
        "realName": "",
        "reverseImgPath": "",
        "userId": "",
        "validDate": ""
      },
      ossData: null
    });

    //获取oss验签
    const initOssConfig = async () => {
      const fileKey = "BLMX/auth";
      const res = await API.uploadOss({
        fileName: "jpg",
        fileType: fileKey,
      });
      if (res.success) {
        state.ossData = res.data
      }
    }

    // 点击认证
    const authVerify = async () => {
      if (state.frontImgPath == null) {
        Toast("请上传身份证正面照");
        return false;
      }
      if (state.reverseImgPath == null) {
        Toast("请上传身份证反面照");
        return false;
      }
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const dataPath = {
        frontImgPath: state.frontImgPath,
        reverseImgPath: state.reverseImgPath,
      };
      try {
        // 请求成功之后 再显示弹框
        const result = await API.authOcr(dataPath);
        if (result.success) {
          state.visible = true;
          state.certInfo = Object.assign({}, state.certInfo, result.data);
          ls.set('authName', state.realName)
        }
      } catch (error) {
        Toast(error.message)
        console.log(error, '获取身份证正反面信息报错');
      }
      Toast.clear();
    };


    // 实名认证提交 face++
    const handleSubmit = async () => {
      if (!state.certInfo.realName) {
        Toast("请填写姓名");
        return false;
      }
      if (!state.certInfo.idCardNumber) {
        Toast("请填写身份证号码");
        return false;
      }
      if (!state.certInfo.validDate) {
        Toast("请填写身份证有效期");
        return false;
      }
      try {
        const form = Object.assign({}, state.certInfo)
        form.idCard = state.certInfo.idCardNumber
        form.issuedBy = state.certInfo.issueBy
        form.overdueDate = state.certInfo.validDate


        const result = await API.authSubmit({...form});
        if (result.success) {
          Jump('/authSuccess')
        }
      } catch (err) {
        Toast(err.message)
        console.log(err)
      }
    };

    onMounted(async () => {
      // ls.remove('authName')
      // const userId =  ls.get('userId')
      // console.log(userId,'用户ID')
      state.certInfo.userId = localStorage.getItem("userId");
      // state.certInfo.userId = '645046808022054419456'
    });

    // 通过组件获取图片信息之后
    const afterRead = async (file, type) => {
      await initOssConfig()
      try {
        const filePath = await UploadFile(state.ossData, file.content)
        switch (type) {
          case "Front":
            state.frontImgPath = filePath
            state.certInfo.frontImgPath = filePath
            break;
          case "Back":
            state.reverseImgPath = filePath
            state.certInfo.reverseImgPath = filePath
            break;
        }
      } catch (err) {
        console.log(err)
        Toast.error('上传图片失败')
      }
    }

    return {
      ...toRefs(state),
      authVerify,
      handleSubmit,
      afterRead
    };
  },
};
</script>
